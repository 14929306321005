<template>
    <ValidationObserver
        ref="observer"
        #default="{ valid }"
        tag="form"
        novalidate
        autocomplete="off"
        method="POST"
        @submit.prevent="onSubmit($event)"
        @focus.capture="$emit('focus')"
    >
        <fieldset>
            <!-- div[role="group"] added for support flex behaviour in fields -->
            <div role="group" class="form-group">
                <!-- @slot Slot for fields elements -->
                <slot />
            </div>
        </fieldset>

        <template v-if="$scopedSlots.submit">
            <!-- @slot Slot for submit btn -->
            <slot :valid="valid" name="submit" />
        </template>
    </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

export default {
    name: 'BaseForm',

    components: {
        ValidationObserver,
    },

    methods: {
        async onSubmit(submitEvent = null) {
            const isValid = await this.validate();

            /**
             * Form validation event
             *
             * @type {Object}
             */
            this.$emit('validate', {
                submitEvent,
                isValid,
            });
        },

        validate() {
            return this.$refs.observer.validate();
        },

        // eslint-disable-next-line vue/no-unused-properties
        resetForm() {
            this.$nextTick(() => {
                if (this.$refs.observer) {
                    this.$refs.observer.reset();
                }
            });
        },
    },
};
</script>
