import { MODULE_NAME } from '@analytics-module/modules/loyalty-club/meta';
import {
    LOYALTY_JOIN_CLICK,
    LOYALTY_JOIN_SUBMIT_CLICK,
    LOYALTY_JOIN_ERROR,
    LOYALTY_JOIN_CLOSE,
    LOYALTY_CLICK_MORE,
    LOYALTY_GO_TO_APP_CLICK,
    THANK_YOU_PAGE_LOGIN_CLICK,
    THANK_YOU_PAGE_REGISTER_CLICK,
    THANK_YOU_PAGE_SIGN_IN_EMAIL_CLICK,
    THANK_YOU_PAGE_FORGOT_PASSWORD_CLICK,
    THANK_YOU_PAGE_RESET_PASSWORD_CLICK,
    THANK_YOU_PAGE_MAGIC_LINK_CLICK,
    LOYALTY_JOIN_BAR_CLICK,
} from '@analytics-module/modules/loyalty-club/types/Events';

export const emitEventOnJoinClick = $analytics => {
    $analytics.moduleEmit(MODULE_NAME, LOYALTY_JOIN_CLICK);
};

export const emitEventOnJoinSubmit = $analytics => {
    $analytics.moduleEmit(MODULE_NAME, LOYALTY_JOIN_SUBMIT_CLICK);
};

export const emitEventOnErrorJoin = $analytics => {
    $analytics.moduleEmit(MODULE_NAME, LOYALTY_JOIN_ERROR);
};

export const emitEventOnJoinClose = $analytics => {
    $analytics.moduleEmit(MODULE_NAME, LOYALTY_JOIN_CLOSE);
};

export const emitEventOnMoreClick = $analytics => {
    $analytics.moduleEmit(MODULE_NAME, LOYALTY_CLICK_MORE);
};

export const emitGoToAppClick = $analytics => {
    $analytics.moduleEmit(MODULE_NAME, LOYALTY_GO_TO_APP_CLICK);
};

export const emitThankYouPageLoginClick = $analytics => {
    $analytics.moduleEmit(MODULE_NAME, THANK_YOU_PAGE_LOGIN_CLICK);
};

export const emitThankYouPageRegisterClick = $analytics => {
    $analytics.moduleEmit(MODULE_NAME, THANK_YOU_PAGE_REGISTER_CLICK);
};

export const emitThankYouPageSignInEmailClick = $analytics => {
    $analytics.moduleEmit(MODULE_NAME, THANK_YOU_PAGE_SIGN_IN_EMAIL_CLICK);
};

export const emitThankYouPageResetPasswordClick = $analytics => {
    $analytics.moduleEmit(MODULE_NAME, THANK_YOU_PAGE_RESET_PASSWORD_CLICK);
};

export const emitThankYouPageForgotPasswordClick = $analytics => {
    $analytics.moduleEmit(MODULE_NAME, THANK_YOU_PAGE_FORGOT_PASSWORD_CLICK);
};

export const emitThankYouPageMagicLinkClick = $analytics => {
    $analytics.moduleEmit(MODULE_NAME, THANK_YOU_PAGE_MAGIC_LINK_CLICK);
};

export const emitThankYouPageJoinBarClick = $analytics => {
    $analytics.moduleEmit(MODULE_NAME, LOYALTY_JOIN_BAR_CLICK);
};
