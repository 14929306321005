export const GOOGLE_EMAIL_DOMAINS = [
    'gmail.com',
    'google.com',
    'googlemail.com',
];

export const YAHOO_EMAIL_DOMAINS = [
    'yahoo.com',
    'yahoo.ro',
    'ymail.com',
    'rocketmail.com',
];

export const EMAIL_DOMAINS = [...GOOGLE_EMAIL_DOMAINS, ...YAHOO_EMAIL_DOMAINS];

export const GOOGLE_URL_PARAM = 'g';
export const YAHOO_URL_PARAM = 'y';

export const EMAIL_URL_PARAMS = [GOOGLE_URL_PARAM, YAHOO_URL_PARAM];
