import {
    EMAIL_DOMAINS,
    GOOGLE_EMAIL_DOMAINS,
    YAHOO_EMAIL_DOMAINS,
    EMAIL_URL_PARAMS,
} from '@configs/sniper-link';

import Google from '@static/icons/24px/google-color.svg?inline';
import Yahoo from '@static/icons/24px/yahoo.svg?inline';

export const getEmailDomain = email => email.split('@')[1];

export const isSniperLinkEnabled = email => {
    const domain = getEmailDomain(email);

    return EMAIL_DOMAINS.includes(domain);
};

export const isSniperLinkParamFromUrl = domain => {
    return EMAIL_URL_PARAMS.includes(domain);
};

export const sniperLinkForGoogle = (email, sender) =>
    // eslint-disable-next-line max-len
    `https://mail.google.com/mail/u/${email}/#search/from%3A(${sender})+in%3Aanywhere+newer_than%3A1h`;

export const sniperLinkForYahoo = sender =>
    `https://mail.yahoo.com/d/search/keyword=from%253A${sender}`;

export const getSniperLink = (email, sender) => {
    const domain = getEmailDomain(email);

    if (GOOGLE_EMAIL_DOMAINS.includes(domain)) {
        return sniperLinkForGoogle(email, sender);
    }

    if (YAHOO_EMAIL_DOMAINS.includes(domain)) {
        return sniperLinkForYahoo(sender);
    }

    return '';
};

export const getSniperLinkIcon = email => {
    const domain = getEmailDomain(email);

    if (GOOGLE_EMAIL_DOMAINS.includes(domain)) {
        return Google;
    }

    if (YAHOO_EMAIL_DOMAINS.includes(domain)) {
        return Yahoo;
    }

    return null;
};

export const getSniperLinkText = (app, email) => {
    const domain = getEmailDomain(email);

    if (GOOGLE_EMAIL_DOMAINS.includes(domain)) {
        return app.$t('Go to Gmail');
    }

    if (YAHOO_EMAIL_DOMAINS.includes(domain)) {
        return app.$t('Go to Yahoo');
    }

    return '';
};

export const isGoogleDomain = email => {
    const domain = getEmailDomain(email);

    return GOOGLE_EMAIL_DOMAINS.includes(domain);
};
