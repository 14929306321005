import { createNamespacedHelpers } from 'vuex';

import { ASYNC_NEWSLETTER_SERVICE_CONFIG } from '@async-services/newsletter/meta';

const { NAME, METHODS } = ASYNC_NEWSLETTER_SERVICE_CONFIG;

const { mapActions: mapActionsMessages } = createNamespacedHelpers('messages');

export default {
    methods: {
        ...mapActionsMessages(['addErrorMessage']),

        async subscribeToNewsletter(
            email,
            gender = null,
            showErrorMessage = true
        ) {
            const {
                success,
                isCustomerVerified,
            } = await this.$asyncServices.use(NAME, METHODS.SUBSCRIBE, {
                email,
                gender,
            });

            if (!success && showErrorMessage) {
                this.addErrorMessage({
                    text: this.$t('Newsletter subscription failed'),
                });
            }

            return { success, isCustomerVerified };
        },
    },
};
