import { createNamespacedHelpers } from 'vuex';

import { STORE_MODULE_NAME as LOYALTY_STORE_MODULE_NAME } from '@loyalty-club/store/meta';

import { isLoyaltyClubEnabled } from '@loyalty-club/assets';

import {
    hasLoyaltyClubStoreModule,
    registerLoyaltyStoreModule,
} from '@loyalty-club/helpers/store';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');
const { mapGetters: mapCustomerGetters } = createNamespacedHelpers('customer');

export default {
    computed: {
        ...mapConfigGetters(['storeCode']),
        ...mapCustomerGetters(['isLoggedIn']),

        isLoyaltyClubEnabled() {
            return isLoyaltyClubEnabled(
                this.$abTests,
                this.storeCode,
                this.$cookies
            );
        },

        isMissingAgreementForJoinToLoyaltyClub() {
            return (
                this.$store.state[LOYALTY_STORE_MODULE_NAME]
                    ?.isMissingAgreementForJoin || false
            );
        },

        isLoyaltyClubMember() {
            if (!this.isLoyaltyClubEnabled) {
                return false;
            }

            if (!this.isLoggedIn) {
                return false;
            }

            return (
                this.$store.getters[
                    `${LOYALTY_STORE_MODULE_NAME}/isLoyaltyMember`
                ] || false
            );
        },

        loyaltyMemberData() {
            if (!this.isLoyaltyClubEnabled) {
                return null;
            }

            if (!this.isLoggedIn) {
                return null;
            }

            return (
                this.$store.state[LOYALTY_STORE_MODULE_NAME]?.memberData || null
            );
        },
    },

    methods: {
        async getLoyaltyMemberData() {
            if (!this.isLoyaltyClubEnabled || !this.isLoggedIn) {
                return;
            }

            if (!hasLoyaltyClubStoreModule(this.$store)) {
                await registerLoyaltyStoreModule(this.$store);
            }

            return this.$store.dispatch(
                `${LOYALTY_STORE_MODULE_NAME}/getLoyaltyMemberData`
            );
        },

        async setMissingAgreementForJoinToLoyaltyClub(isMissing) {
            if (!this.isLoyaltyClubEnabled || !this.isLoggedIn) {
                return;
            }

            if (!hasLoyaltyClubStoreModule(this.$store)) {
                await registerLoyaltyStoreModule(this.$store);
            }

            return this.$store.dispatch(
                `${LOYALTY_STORE_MODULE_NAME}/setMissingAgreementForJoin`,
                isMissing
            );
        },

        setLeftStatusForMember() {
            if (!this.loyaltyMemberData) {
                return;
            }

            this.$store.dispatch(
                `${LOYALTY_STORE_MODULE_NAME}/setLeftStatusForLoyaltyMember`
            );
        },
    },
};
