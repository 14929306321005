<template>
    <WithScrollLock :should-lock="show">
        <DialogCentered
            :show="show"
            :label="$tLoyalty('Join the eobuwie Club')"
            class="join-to-loyalty-club-modal"
            @close="closeModal(true)"
            @button-trailing-click="closeModal(true)"
        >
            <template #icon-trailing>
                <Icon :icon="Close" />
            </template>

            <div class="content">
                <Skeleton
                    v-if="isLoading"
                    class="form-skeleton"
                    height="40px"
                />

                <BodyText v-else :size="BODY_M">
                    {{ description }}
                </BodyText>

                <BaseForm @validate="join($event)">
                    <Skeleton v-if="isLoading" height="60px" />

                    <FieldValidator
                        v-else-if="newsletterAgreement"
                        #default="{ errors, reset }"
                        :name="
                            getAgreementCheckboxKey(newsletterAgreement.code)
                        "
                        :rules="{ required: { allowFalse: false } }"
                    >
                        <Checkbox
                            :id="
                                getAgreementCheckboxKey(
                                    newsletterAgreement.code
                                )
                            "
                            v-model="isNewsletterAgreementAccepted"
                            :name="
                                getAgreementCheckboxKey(
                                    newsletterAgreement.code
                                )
                            "
                            :invalid="!!errors[0]"
                            class="agreement-checkbox"
                            @click="reset()"
                        >
                            <span v-html="newsletterAgreement.input_text" />

                            <template v-if="errors[0]" #error>
                                {{ errors[0] }}
                            </template>
                        </Checkbox>
                    </FieldValidator>

                    <template #submit>
                        <ButtonLarge
                            class="join-button"
                            :type="TYPE_SUBMIT"
                            :variant="PRIMARY"
                            :loading="isPending || isLoading"
                        >
                            {{ $tLoyalty('Join') }}
                        </ButtonLarge>
                    </template>
                </BaseForm>

                <div class="regulation-info">
                    <Skeleton v-if="isLoading" height="15px" />

                    <span v-else v-html="regulationInfo" />
                </div>
            </div>
        </DialogCentered>
    </WithScrollLock>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { CLIENT_IDENTITY_DOMAIN } from '@errors/feature-domain-names';

import {
    LOYALTY_TERMS,
    LOYALTY_CLUB,
    IS_SUBSCRIBED_CODE,
    SOURCE_TYPE,
} from '@configs/gdpr';
import {
    MODAL_ACCOUNT_VERIFICATION,
    MODAL_ACCOUNT_VERIFICATION_TYPE,
} from '@configs/modals';
import { ACCOUNT_VERIFICATION_STATES } from '@configs/account-verification';

import { isSniperLinkEnabled } from '@assets/sniper-link';

import newsletter from '@mixins/newsletter';

import {
    emitEventOnJoinSubmit,
    emitEventOnErrorJoin,
    emitEventOnJoinClose,
} from '@loyalty-club/assets/google-analytics-events';

import { TOPICS } from '@loyalty-club/models/PubSub';

import WithLoyaltyClubStore from '@loyalty-club/mixins/WithLoyaltyClubStore';

import WithScrollLock from '@loyalty-club-functionals/WithScrollLock/WithScrollLock';
import { mobileAppEvents } from '@loyalty-club/config';

import BaseForm from '@molecules/BaseForm/BaseForm';
import FieldValidator from '@molecules/FieldValidator/FieldValidator';

import { Checkbox } from '@eobuwie-ui/components/Checkbox/v1';
import {
    DialogCentered,
    DIALOG_CENTERED_VARIANTS,
} from '@eobuwie-ui/components/DialogCentered/v1';
import { Icon } from '@eobuwie-ui/components/Icon/v1';
import { BodyText, BODY_TEXT_SIZES } from '@eobuwie-ui/components/BodyText/v1';
import {
    ButtonLarge,
    BUTTON_LARGE_VARIANTS,
    BUTTON_LARGE_TYPES,
} from '@eobuwie-ui/components/ButtonLarge/v1';
import { Skeleton } from '@eobuwie-ui/components/Skeleton/v1';

import { Close } from '@eobuwie-ui/icons/v1/action';

const {
    mapState: mapGdprState,
    mapActions: mapGdprActions,
} = createNamespacedHelpers('gdpr');
const { mapActions: mapMessageActions } = createNamespacedHelpers('messages');
const {
    mapGetters: mapCustomerGetters,
    mapActions: mapCustomerActions,
} = createNamespacedHelpers('customer');
const { mapActions: mapModalsActions } = createNamespacedHelpers('modals');

export default {
    name: 'JoinToLoyaltyClubModal',

    components: {
        DialogCentered,
        Icon,
        BodyText,
        ButtonLarge,
        Checkbox,
        FieldValidator,
        BaseForm,
        Skeleton,
        WithScrollLock,
    },

    mixins: [newsletter, WithLoyaltyClubStore],

    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isLoading: true,
            isPending: false,
            isNewsletterAgreementAccepted: false,
        };
    },

    computed: {
        ...mapCustomerGetters(['customer', 'isLoggedIn']),
        ...mapGdprState({
            loyaltyClubAgreements: LOYALTY_CLUB,
        }),

        description() {
            return this.$tLoyalty(
                this.newsletterAgreement
                    ? 'To join the eobuwie Club, agree to receive the Newsletter'
                    : 'Collect points and exchange them for discounts at eobuwie and MODIVO!'
            );
        },

        regulationInfo() {
            return this.$tLoyalty(
                'By clicking the Join button, you accept the eobuwie Club Regulations',
                {
                    regulationLink: this.$tPath('regulation-for-loyalty-club'),
                }
            );
        },

        newsletterAgreement() {
            return (
                this.loyaltyClubAgreements.find(
                    ({ code }) => code === IS_SUBSCRIBED_CODE
                ) || null
            );
        },

        loyaltyClubAgreement() {
            return (
                this.loyaltyClubAgreements.find(
                    ({ code }) => code === LOYALTY_TERMS
                ) || null
            );
        },

        gdprAgreementsDecision() {
            if (!this.loyaltyClubAgreement) {
                return [];
            }

            const agreements = [
                {
                    agreement_id: this.loyaltyClubAgreement.id,
                    is_accepted: true,
                },
            ];

            if (this.newsletterAgreement) {
                agreements.push({
                    agreement_id: this.newsletterAgreement.id,
                    is_accepted: this.isNewsletterAgreementAccepted,
                });
            }

            return agreements;
        },
    },

    watch: {
        isLoggedIn() {
            if (!this.isLoggedIn) {
                this.closeModal();
            }
        },

        show: {
            async handler(show) {
                if (!show) {
                    return;
                }

                await this.initModal();
            },
        },
    },

    beforeCreate() {
        this.Close = Close;
        this.VARIANT_S = DIALOG_CENTERED_VARIANTS.S;
        this.BODY_M = BODY_TEXT_SIZES.M;
        this.PRIMARY = BUTTON_LARGE_VARIANTS.PRIMARY;
        this.TYPE_SUBMIT = BUTTON_LARGE_TYPES.SUBMIT;
    },

    async mounted() {
        if (!this.show) {
            return;
        }

        await this.initModal();
    },

    methods: {
        ...mapGdprActions(['getGdprData', 'setGdprData']),
        ...mapMessageActions(['addSuccessMessage', 'addErrorMessage']),
        ...mapCustomerActions(['setShouldOpenModalNewsletterConfirmation']),
        ...mapModalsActions(['setShouldOpenSniperLinkModal']),

        async initModal() {
            if (!this.isLoggedIn) {
                this.closeModal();

                return;
            }

            this.isLoading = true;

            const isSuccess = await this.getGdprData({
                viewId: LOYALTY_CLUB,
                forViewId: LOYALTY_CLUB,
                customerEmail: this.customer.email,
            });

            if (!isSuccess || !this.loyaltyClubAgreement) {
                await this.setMissingAgreementForJoinToLoyaltyClub(true);

                return;
            }

            await this.setMissingAgreementForJoinToLoyaltyClub(false);

            this.isNewsletterAgreementAccepted = false;

            this.isLoading = false;
        },

        showErrorMessage() {
            this.addErrorMessage({
                text: this.$tLoyalty(
                    'Registration for the eobuwie Club has failed Try again'
                ),
            });
        },

        showSuccessMessage() {
            this.addSuccessMessage({
                text: this.$tLoyalty('Welcome to the eobuwie Club!'),
            });
        },

        sendSignInEventForMobileApp() {
            try {
                this.$mobileApp.emitMessageForMobileApp(
                    mobileAppEvents.signInToLoyaltyClub
                );
            } catch (error) {
                this.$errorHandler.captureDomainError(
                    CLIENT_IDENTITY_DOMAIN,
                    new Error(error?.message || 'UNKNOWN_ERROR', {
                        cause: error,
                    }),
                    {
                        [ERROR_ACTION_TAG_NAME]:
                            'joinToLoyaltyClubModal/emitMessageForMobileApp/signInToLoyaltyClub',
                    }
                );
            }
        },

        async join({ isValid }) {
            if (!isValid) {
                emitEventOnErrorJoin(this.$analytics);

                return;
            }

            emitEventOnJoinSubmit(this.$analytics);

            this.isPending = true;

            const isSuccess = await this.setGdprData({
                customerEmail: this.customer.email,
                decisions: this.gdprAgreementsDecision,
                layoutHandle: LOYALTY_CLUB,
                source: SOURCE_TYPE,
            });

            if (!isSuccess) {
                this.showErrorMessage();

                this.isPending = false;

                return;
            }

            if (!this.newsletterAgreement) {
                this.$loyaltyPubSub?.publish(TOPICS.LOYALTY_CLUB_SIGN_IN);

                this.showSuccessMessage();

                this.isPending = false;

                this.closeModal();

                return;
            }

            const { success } = await this.subscribeToNewsletter(
                this.customer.email,
                null,
                false
            );

            if (!success) {
                this.showErrorMessage();

                this.isPending = false;

                return;
            }

            if (isSniperLinkEnabled(this.customer.email)) {
                this.setShouldOpenSniperLinkModal(true);
            } else {
                this.setShouldOpenModalNewsletterConfirmation(true);
            }

            const isVerificationModalOpen = this.$modals.isOpen(
                MODAL_ACCOUNT_VERIFICATION
            );
            const isVerificationStatusPending =
                this.$modals.getConfig(MODAL_ACCOUNT_VERIFICATION)[
                    MODAL_ACCOUNT_VERIFICATION_TYPE
                ] === ACCOUNT_VERIFICATION_STATES.PENDING;

            if (isVerificationModalOpen && isVerificationStatusPending) {
                this.$modals.close(MODAL_ACCOUNT_VERIFICATION);
            }

            if (this.$mobileApp.isWebView) {
                this.sendSignInEventForMobileApp();
            }

            this.$loyaltyPubSub?.publish(TOPICS.LOYALTY_CLUB_SIGN_IN);

            this.showSuccessMessage();

            this.isPending = false;

            this.closeModal();
        },

        closeModal(isUserAction = false) {
            this.$emit('close');

            if (!isUserAction) {
                return;
            }

            emitEventOnJoinClose(this.$analytics);
        },

        getAgreementCheckboxKey(code) {
            return `loyalty-club-${code}`;
        },
    },
};
</script>

<style lang="scss" scoped>
.join-to-loyalty-club-modal {
    @apply fixed top-0 bottom-0 left-0 right-0 z-8;

    .content {
        @apply px-3 pt-3 pb-40p w-full;

        .form-skeleton {
            @apply mt-3;
        }

        .join-button {
            @apply w-full mt-3;
        }

        .regulation-info {
            @apply mt-3 text-xs leading-xs;

            &:deep(.link) {
                @apply underline;
            }
        }

        .agreement-checkbox {
            @apply mt-3;

            &:deep(.link) {
                @apply underline;
            }
        }
    }
}
</style>
